/* eslint-disable react/jsx-indent-props */
import { ReactComponent as ArrowLeft } from 'Style/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'Style/icons/arrow-right.svg';

export * from 'SourceComponent/ProductList/ProductList.config';

export const sliderSettings = (isSimilarProduct = false) => ({
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: (
        <button>
            <div
                block="NewProducts"
                elem="CustomArrow"
            >
                <ArrowRight />
            </div>
        </button>
    ),
    prevArrow: (
        <button>
            <div
                block="NewProducts"
                elem="CustomArrow"
            >
                <ArrowLeft />
            </div>
        </button>
    ),
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: isSimilarProduct ? 2 : 1,
                slidesToScroll: 1,
            },
        },
    ],
});
