import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductListContainer as SourceProductListContainer,
} from 'SourceComponent/ProductList/ProductList.container';

/** @namespace Satisfly/Component/ProductList/Container */
export class ProductListContainer extends SourceProductListContainer {
    static defaultProps = {
        ...super.defaultProps,
        pageSize: 24,
        isInfiniteLoaderEnabled: false,
    };

    containerProps() {
        const { paramsJson } = this.props;

        return {
            ...super.containerProps(),
            paramsJson,
        };
    }

    componentDidUpdate(prevProps) {
        const {
            sort,
            search,
            filter,
            pages,
            device,
            isPlp,
            isWidget,
            pageSize,
        } = this.props;

        const {
            sort: prevSort,
            search: prevSearch,
            filter: prevFilter,
            location: prevLocation,
            pageSize: prevPageSize,
        } = prevProps;

        const { pagesCount } = this.state;
        const pagesLength = Object.keys(pages).length;

        if (pagesCount !== pagesLength) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ pagesCount: pagesLength });
        }

        const prevPage = this._getPageFromUrl(prevLocation);
        const currentPage = this._getPageFromUrl();

        if (
            JSON.stringify(filter) !== JSON.stringify(prevFilter)
            || JSON.stringify(sort) !== JSON.stringify(prevSort)
            || currentPage !== prevPage
        ) {
            window.isPrefetchValueUsed = false;
        }

        // prevents requestPage() fired twice on Mobile PLP with enabled infinite scroll
        if (device.isMobile && this._getIsInfiniteLoaderEnabled() && isPlp) {
            return;
        }

        if (device.isMobile && isWidget) {
            return;
        }

        if (search !== prevSearch
            || currentPage !== prevPage
            || JSON.stringify(sort) !== JSON.stringify(prevSort)
            || JSON.stringify(filter) !== JSON.stringify(prevFilter)
            || pageSize !== prevPageSize
        ) {
            this.requestPage(this._getPageFromUrl());
        }
    }

    _getIsInfiniteLoaderEnabled() { // disable infinite scroll on mobile
        const { isInfiniteLoaderEnabled = false, device } = this.props;

        // allow scroll and mobile
        if (device.isMobile) {
            return isInfiniteLoaderEnabled;
        }

        return false;
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ProductListContainer),
);
