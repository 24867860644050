/* eslint-disable max-lines */
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
    CategoryPageLayout,
    LOADING_TIME,
} from 'Route/CategoryPage/CategoryPage.config';
import {
    CategoryPageContainer as SourceCategoryPageContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceRoute/CategoryPage/CategoryPage.container';
import { setUrlRewrite } from 'Store/UrlRewrites/UrlRewrites.action';
import history from 'Util/History';
import { debounce } from 'Util/Request/Debounce';
import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';
import { RootState } from 'Util/Store/Store.type';
import { appendWithStoreCode, getQueryParam, setQueryParams } from 'Util/Url';

import { resetProductList } from '../../store/ProductList/ProductList.action';
import {
    gridPerPageDefaultValues,
    listPerPageDefaultValues,
} from './CategoryPage.config';
import {
    CategoryPageComponentProps,
    CategoryPageContainerFunctions,
    CategoryPageContainerMapStateProps,
    CategoryPageContainerProps,
    CategoryPageContainerPropsKeys,
    CategoryPageContainerState,
} from './CategoryPage.type';
/** @namespace Satisfly/Route/CategoryPage/Container/mapStateToProps */
export const mapStateToProps = (
    state: RootState,
): CategoryPageContainerMapStateProps => ({
    ...sourceMapStateToProps(state),
    isMobile: state.ConfigReducer.device.isMobile,
    // @ts-ignore
    gridPerPageValues: state.ConfigReducer.grid_per_page_values,
    catalogFrontendDefaultSortBy: state.ConfigReducer.catalog_default_sort_by,
    // @ts-ignore
    listPerPageValues: state.ConfigReducer.list_per_page_values,
    // @ts-ignore
    gridPerPage: state.ConfigReducer.grid_per_page,
    // @ts-ignore
    listPerPage: state.ConfigReducer.list_per_page,
});

/** @namespace Satisfly/Route/CategoryPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch): any => ({
    ...sourceMapDispatchToProps(dispatch),
    resetPLP: () => dispatch(resetProductList()),
    setUrlRewrite: (url: string) => dispatch(setUrlRewrite(url)),
});

/** @namespace Satisfly/Route/CategoryPage/Container */
export class CategoryPageContainer<
    P extends CategoryPageContainerProps = CategoryPageContainerProps,
    S extends CategoryPageContainerState = CategoryPageContainerState,
> extends SourceCategoryPageContainer<P, S> {
    __construct(props: P): void {
        super.__construct?.(props);

        const { location } = history;

        this.state = {
            currentCategoryIds: -1,
            breadcrumbsWereUpdated: false,
            selectedLayoutType: undefined,
            defaultPlpType: undefined,
            activeLayoutType: undefined,
            plpTypes: [] as CategoryPageLayout[],
            goToPageValue: getQueryParam('page', location) || 1,
        } as S;

        this.setOfflineNoticeSize = this.setOfflineNoticeSize.bind(this);
    }

    containerFunctions: CategoryPageContainerFunctions = {
        onSortChange: this.onSortChange.bind(this),
        onGridButtonClick: this.onGridButtonClick.bind(this),
        onListButtonClick: this.onListButtonClick.bind(this),
        onFilterButtonClick: this.onFilterButtonClick.bind(this),
        loadPageFromInput: this.loadPageFromInput.bind(this),
        setGoToPageValue: this.setGoToPageValue.bind(this),
        onPerPageChange: this.onPerPageChange.bind(this),
    };

    static getDerivedStateFromProps(
        props: CategoryPageContainerProps,
        state: CategoryPageContainerState,
    ): Partial<CategoryPageContainerState> | null {
        const { currentCategoryIds, defaultPlpType, plpTypes } = state;

        const {
            category: { id },
            plpType,
        } = props;

        const update = {};

        const { location } = history;
        const currentPage = getQueryParam('page', location);

        Object.assign(update, { goToPageValue: currentPage });

        /**
         * Determine default plpType and the other ones
         */
        if (!defaultPlpType || !plpTypes) {
            if (plpType.match('-')) {
                const plpTypes = plpType.split('-');

                Object.assign(update, {
                    defaultPlpType: plpTypes[0],
                    plpTypes,
                });
            } else {
                Object.assign(update, {
                    defaultPlpType: plpType,
                    plpTypes: [plpType],
                });
            }
        }

        /**
         * If the category we expect to load is loaded - reset it
         */
        if (currentCategoryIds === id) {
            Object.assign(update, { currentCategoryIds: -1 });
        }

        if (!Object.keys(update).length) {
            return null;
        }

        return update;
    }

    componentDidUpdate(prevProps: CategoryPageContainerProps): void {
        const {
            isOffline,
            categoryIds,
            category: { id },
            currentArgs: { filter } = {},
        } = this.props;

        const { breadcrumbsWereUpdated } = this.state;

        const {
            categoryIds: prevCategoryIds,
            category: { id: prevId },
            currentArgs: { filter: prevFilter } = {},
        } = prevProps;

        // TODO: category scrolls up when coming from PDP

        if (isOffline) {
            debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        }

        /**
         * If the URL rewrite has been changed, make sure the category ID
         * will persist in the history state.
         */
        if (categoryIds !== prevCategoryIds) {
            this.updateHistory();
        }

        /**
         * If the currently loaded category ID does not match the ID of
         * category from URL rewrite, request category.
         */
        if (categoryIds !== id) {
            waitForPriorityLoad().then(
                /** @namespace Satisfly/Route/CategoryPage/Container/CategoryPageContainer/componentDidUpdate/waitForPriorityLoad/then */
                () => this.requestCategory(),
            );
        }

        /**
         * If category ID was changed => it is loaded => we need to
         * update category specific information, i.e. breadcrumbs.
         *
         * Or if the breadcrumbs were not yet updated after category request,
         * and the category ID expected to load was loaded, update data.
         */
        const categoryChange = id !== prevId || (!breadcrumbsWereUpdated && id === categoryIds);

        if (categoryChange) {
            this.checkIsActive();
            this.updateMeta();
            this.updateBreadcrumbs();
            this.updateHeaderState();
        }

        /*
         ** if category wasn't changed we still need to update meta for correct robots meta tag [#928](https://github.com/scandipwa/scandipwa-theme/issues/928)
         */
        if (
            !categoryChange
            && filter?.customFilters
            && prevFilter?.customFilters
            && Object.keys(filter.customFilters).length
                !== Object.keys(prevFilter.customFilters).length
        ) {
            this.updateMeta();
        }
    }

    generatePerPageOptions() {
        const { gridPerPageValues, listPerPageValues } = this.props;
        const { defaultPlpType, selectedLayoutType } = this.state;

        const activeLayoutType = selectedLayoutType ?? defaultPlpType;

        switch (activeLayoutType) {
        case CategoryPageLayout.GRID:
            return (
                gridPerPageValues?.split(',').map((value) => ({
                    label: `${__('Show on page: ')} ${value}`,
                    value,
                })) ?? gridPerPageDefaultValues
            );
        case CategoryPageLayout.LIST:
            return (
                listPerPageValues?.split(',').map((value) => ({
                    label: `${__('Show on page: ')} ${value}`,
                    value,
                })) ?? listPerPageDefaultValues
            );
        default:
            return [];
        }
    }

    onPerPageChange(e: string) {
        const { location } = history;

        setQueryParams(
            { ...this.getSelectedSortFromUrl(), page: '' },
            location,
            history,
        );

        this.setState({
            pageSize: Number(e),
        });
    }

    loadPageFromInput(value: string) {
        const SCROLL_DEBOUNCE_DELAY = 200;

        const currentUrl = history.location.pathname;

        const timeoutId = setTimeout(() => {
            history.replace(appendWithStoreCode(`${currentUrl}?page=${value}`));
        }, SCROLL_DEBOUNCE_DELAY);

        return () => clearTimeout(timeoutId);
    }

    setGoToPageValue(newValue: string) {
        this.setState({
            goToPageValue: newValue,
        });
    }

    containerProps(): Pick<
    CategoryPageComponentProps,
    CategoryPageContainerPropsKeys
    > {
        const { currentArgs: { currentPage } = {} } = this.props;

        const { goToPageValue, pageSize } = this.state;

        return {
            ...super.containerProps(),
            goToPageValue: goToPageValue || currentPage,
            perPageOptions: this.generatePerPageOptions(),
            pageSize,
        };
    }

    componentWillUnmount(): void {
        const { resetPLP, setUrlRewrite } = this.props;

        if (resetPLP && setUrlRewrite) {
            setUrlRewrite('');
            resetPLP();
            window.actionName = {
                id: null as any,
                type: '',
            };
        }
    }

    getIsMatchingInfoFilter(): boolean {
        const {
            categoryIds,
            category: { id = null } = {},
            selectedInfoFilter: {
                // TODO
                categoryIds: selectedCategoryIds = window.actionName?.id,
            },
        } = this.props;

        // Requested category is equal to current category
        return categoryIds === selectedCategoryIds || id === selectedCategoryIds;
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    // @ts-ignore
    CategoryPageContainer,
);
