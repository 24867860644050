/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import CategoryProductList from 'Component/CategoryProductList';
import ContentWrapper from 'Component/ContentWrapper';
import Field from 'Component/Field';
import { FieldType } from 'Component/Field/Field.config';
import TextPlaceholder from 'Component/TextPlaceholder';
import { CategoryPageComponent as SourceCategoryPageComponent } from 'SourceRoute/CategoryPage/CategoryPage.component';
import { ReactElement } from 'Type/Common.type';
import { isCrawler, isSSR } from 'Util/Browser';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';

import { CategoryPageLayout } from './CategoryPage.config';
import {
    CategoryPageComponentProps,
    CategoryPageComponentState,
} from './CategoryPage.type';

import './CategoryPage.override.style';

/** @namespace Satisfly/Route/CategoryPage/Component */
export class CategoryPageComponent<
    P extends CategoryPageComponentProps = CategoryPageComponentProps,
    S extends CategoryPageComponentState = CategoryPageComponentState,
> extends SourceCategoryPageComponent<P, S> {
    renderCategoryProductList(): ReactElement {
        const {
            filter,
            search,
            selectedSort,
            selectedFilters,
            isMatchingListFilter,
            isCurrentCategoryLoaded,
            isMatchingInfoFilter,
            pageSize,
        } = this.props;

        const { activeLayoutType } = this.state;

        if (!this.displayProducts()) {
            setLoadedFlag();

            return null;
        }

        return (
            <div
              block="CategoryPage"
              elem="ProductListWrapper"
              mods={ { isPrerendered: isSSR() || isCrawler() } }
            >
                { this.renderItemsCount(true) }
                <CategoryProductList
                  filter={ filter }
                  search={ search }
                  sort={ selectedSort }
                  selectedFilters={ selectedFilters }
                  isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
                  isMatchingListFilter={ isMatchingListFilter }
                  isMatchingInfoFilter={ isMatchingInfoFilter }
                  layout={ activeLayoutType || CategoryPageLayout.GRID }
                    // @ts-ignore
                  pageSize={ pageSize }
                />
            </div>
        );
    }

    renderFilterButton(): ReactElement {
        const {
            isContentFiltered,
            totalPages,
            category: { is_anchor },
            isSearchPage,
            isCurrentCategoryLoaded,
            isMatchingInfoFilter,
            onFilterButtonClick,
        } = this.props;

        if (!isMatchingInfoFilter) {
            return this.renderFilterButtonPlaceholder();
        }

        if (
            (!isContentFiltered && totalPages === 0)
            || (!is_anchor && !isSearchPage)
            || !isCurrentCategoryLoaded
        ) {
            return null;
        }

        return (
            <button
              block="CategoryPage"
              elem="Filter"
              onClick={ onFilterButtonClick }
            >
                <span>{ __('Filters') }</span>
                { this.renderFiltersCount() }
            </button>
        );
    }

    renderFiltersCount(): ReactElement {
        const { appliedFiltersCount } = this.props;

        if (!appliedFiltersCount) {
            return null;
        }

        return (
            <span block="CategoryPage" elem="ActiveFiltersCount">
                { appliedFiltersCount }
            </span>
        );
    }

    renderCategoryText(): ReactElement {
        const {
            category: { name },
            isCurrentCategoryLoaded,
            isMatchingInfoFilter,
        } = this.props;
        const {
            actionName: { name: preloadName },
            isPrefetchValueUsed,
        } = window;

        const categoryName = isPrefetchValueUsed ? preloadName : name;

        if (isMatchingInfoFilter || isCurrentCategoryLoaded) {
            return <TextPlaceholder content={ categoryName } />;
        }

        return <TextPlaceholder />;
    }

    renderCategoryName(): ReactElement {
        const {
            category: { name, id },
        } = this.props;
        const { isPrefetchValueUsed } = window;

        if (!id && !name && !isPrefetchValueUsed) {
            return null;
        }

        return (
            <h1 block="CategoryDetails" elem="Heading">
                { this.renderCategoryText() }
            </h1>
        );
    }

    renderGoToPage(): ReactElement {
        const {
            totalPages,
            goToPageValue,
            setGoToPageValue,
            loadPageFromInput,
        } = this.props;

        return (
            <div block="CategoryPage" elem="GoToPage">
                <p>{ __('Go to') }</p>
                <Field
                  type={ FieldType.TEXT }
                  attr={ {
                      id: 'page_number',
                      name: 'page_number',
                      value: goToPageValue,
                  } }
                  events={ {
                      onChange: ({ target: { value = '' } = {} }) => {
                          if (!value) {
                              setGoToPageValue('');
                          }

                          // eslint-disable-next-line
                          const isNumber = typeof parseInt(value) === 'number';
                          const isInteger = isNumber && Number.isInteger(Number(value));
                          const isInPagesRange = Number(value) > 0
                                && Number(value) <= totalPages;

                          if (isInteger && isInPagesRange) {
                              loadPageFromInput(value);
                              setGoToPageValue(value);
                          } else {
                              setGoToPageValue('');
                          }
                      },
                  } }
                />
                <span>{ `${__('from2')} ${totalPages}` }</span>
            </div>
        );
    }

    renderPerPage(): ReactElement {
        const { onPerPageChange, pageSize, perPageOptions } = this.props;

        return (
            <div block="CategoryPage" elem="PerPage">
                <Field
                  type={ FieldType.SELECT }
                  attr={ {
                      id: 'per-page',
                      name: 'per-page',
                      value: pageSize,
                      noPlaceholder: true,
                  } }
                  events={ {
                      onChange: onPerPageChange,
                  } }
                  options={ perPageOptions }
                  mix={ { block: 'CategoryPage', elem: 'PerPageSelect' } }
                />
            </div>
        );
    }

    renderMiscellaneous(): ReactElement {
        const { totalItems, isMobile } = this.props;

        if (totalItems === 0 || !this.displayProducts()) {
            return (
                <aside
                  block="CategoryPage"
                  elem="Miscellaneous"
                  mods={ { noResults: true } }
                />
            );
        }

        return (
            <aside block="CategoryPage" elem="Miscellaneous">
                <div block="CategoryPage" elem="MiscellaneousLayoutWrapper">
                    <div
                      block="CategoryPage"
                      elem="LayoutWrapper"
                      mods={ { isPrerendered: isSSR() || isCrawler() } }
                    >
                        { !isMobile && this.renderItemsCount() }
                        <div block="CategoryPage" elem="NavSelects">
                            { !isMobile && this.renderPerPage() }
                            { this.renderCategorySort() }
                        </div>
                        { !isMobile && this.renderGoToPage() }
                    </div>
                    <div
                      block="CategoryPage"
                      elem="LayoutWrapper"
                      mods={ { isPrerendered: isSSR() || isCrawler() } }
                    >
                        { this.renderFilterButton() }
                    </div>
                </div>
            </aside>
        );
    }

    renderContent(): ReactElement {
        return (
            <>
                { this.renderFilterOverlay() }
                { this.renderCategoryName() }
                { this.renderCmsBlock() }
                { this.renderMiscellaneous() }
                { this.renderCategoryProductList() }
            </>
        );
    }

    render(): ReactElement {
        const hideProducts = !this.displayProducts();
        const { totalItems } = this.props;

        return (
            <main block="CategoryPage" mods={ { noResults: totalItems === 0 } }>
                <ContentWrapper
                  wrapperMix={ {
                      block: 'CategoryPage',
                      elem: 'Wrapper',
                      mods: { hideProducts },
                  } }
                  label="Category page"
                >
                    { this.renderContent() }
                </ContentWrapper>
                <ContentWrapper>{ this.renderCategoryDetails() }</ContentWrapper>
            </main>
        );
    }
}

export default CategoryPageComponent;
