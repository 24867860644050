/* eslint-disable no-nested-ternary */
import Link from 'Component/Link';
import ProductListPage from 'Component/ProductListPage';
import {
    ProductListComponent as SourceProductListComponent,
} from 'SourceComponent/ProductList/ProductList.component';
import { ReactComponent as ArrowRight } from 'Style/icons/arrow-right.svg';

import './ProductList.style.override';

/** @namespace Satisfly/Component/ProductList/Component */
export class ProductListComponent extends SourceProductListComponent {
    renderPage(props = {}) {
        const {
            isInfiniteLoaderEnabled,
            loadPage,
            isLoading,
            isVisible,
            mix,
            isPlp,
            isWidget,
            device,
        } = this.props;
        const {
            items = [],
            pageNumber = 0,
            selectedFilters = {},
            wrapperRef,
            key,
        } = this._processProps(props);

        return (
            <ProductListPage
              key={ key }
              isInfiniteLoaderEnabled={ isInfiniteLoaderEnabled }
              updatePages={ loadPage }
              isLoading={ isLoading }
              isVisible={ isVisible }
              mix={ mix }
              items={ items }
              pageNumber={ pageNumber }
              selectedFilters={ selectedFilters }
              wrapperRef={ wrapperRef }
              isPlp={ isPlp }
              isWidget={ isWidget }
              device={ device }
            />
        );
    }

    renderTitle() {
        const { title, isWidget, paramsJson } = this.props;

        if (!title) {
            return null;
        }

        // Temporary solution, need to extend widget
        if (['Wybrane dla Ciebie', 'Wyprzedaż'].indexOf(title) !== -1) {
            const url = title === 'Wybrane dla Ciebie'
                ? 'wybrane-dla-ciebie' : title === 'Wyprzedaż'
                    ? 'wyprzedaz' : null;

            if (!url) {
                return null;
            }

            return (
                <div block="ProductList" elem="BlockTitleWrapper">
                    <h2>{ title }</h2>
                    { isWidget && (
                        <Link
                          mix={ { block: 'ProductList', elem: 'SeeMore' } }
                          // to do link to product list with encoded position
                          to={ url }
                        >
                          { __('See more') }
                          <ArrowRight />
                        </Link>
                    ) }
                </div>
            );
        }

        return (
            <div block="ProductList" elem="BlockTitleWrapper">
                <h2>{ title }</h2>
                { isWidget && paramsJson?.url && (
                    <Link
                      mix={ { block: 'ProductList', elem: 'SeeMore' } }
                      // to do link to product list with encoded position
                      to={ paramsJson?.url || '' }
                    >
                      { __('See more') }
                      <ArrowRight />
                    </Link>
                ) }
            </div>
        );
    }
}

export default ProductListComponent;
