/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import TextPlaceholder from 'Component/TextPlaceholder';
import {
    CategoryItemsCountComponent as SourceCategoryItemsCountComponent,
} from 'SourceComponent/CategoryItemsCount/CategoryItemsCount.component';
import { ReactElement } from 'Type/Common.type';

/** @namespace Satisfly/Component/CategoryItemsCount/Component */
export class CategoryItemsCountComponent extends SourceCategoryItemsCountComponent {
    render(): ReactElement {
        const {
            totalItems,
            isMatchingListFilter,
        } = this.props;

        return (
            <p block="CategoryPage" elem="ItemsCount">
                <TextPlaceholder
                  content={ (!isMatchingListFilter
                      ? __('Products are loading...')
                      : __('Products count: %s', totalItems)
                  ) }
                />
            </p>
        );
    }
}

export default CategoryItemsCountComponent;
