/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    CategoryDetailsComponent as SourceCategoryDetailsComponent,
} from 'SourceComponent/CategoryDetails/CategoryDetails.component';

import './CategoryDetails.override.style';

/** @namespace Satisfly/Component/CategoryDetails/Component */
export class CategoryDetailsComponent extends SourceCategoryDetailsComponent {
    renderCategoryName() {
        return null;
    }
}

export default CategoryDetailsComponent;
