/* eslint-disable react/no-array-index-key */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import SlickSlider from 'react-slick';

import ProductCard from 'Component/ProductCard';
import { sliderSettings } from 'Component/ProductList/ProductList.config';
import { CategoryPageLayout } from 'Route/CategoryPage/CategoryPage.config';
import {
    ProductListPageComponent as SourceProductListPageComponent,
} from 'SourceComponent/ProductListPage/ProductListPage.component';
import { PRODUCTS_PRELOAD_COUNT } from 'Util/Product';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';
import { AfterPriority } from 'Util/Request/LowPriorityRender';

import './ProductListPage.style.override';

/** @namespace Satisfly/Component/ProductListPage/Component */
export class ProductListPageComponent extends SourceProductListPageComponent {
    componentDidMount() {
        const { isWidget } = this.props;

        if (!isWidget) {
            this.startObserving();
        }
    }

    componentDidUpdate() {
        const { isWidget } = this.props;

        if (!isWidget) {
            this.startObserving();
        }
    }

    renderPageItems() {
        const {
            items,
            selectedFilters,
            mix: {
                mods: {
                    layout = CategoryPageLayout.GRID,
                } = {},
            },
            isWidget,
        } = this.props;

        return items.map((product, i) => {
            if (i < PRODUCTS_PRELOAD_COUNT) {
                return (
                    <div block="ProductListPage" elem="ProductCardWrapper" mods={ { isWidget } } key={ `${product?.id}_${i}` }>
                        <ProductCard
                          product={ product }
                        // eslint-disable-next-line react/no-array-index-key
                          selectedFilters={ selectedFilters }
                          layout={ layout }
                          { ...this.containerProps() }
                          onLoad={ setLoadedFlag }
                          isWidget={ isWidget }
                        />
                    </div>
                );
            }

            return (
                // @ts-ignore
                <AfterPriority fallback={ <div style={ { minHeight: 200 } } key={ `${product?.id}_${i}` } /> }>
                    <ProductCard
                      product={ product }
                    // eslint-disable-next-line react/no-array-index-key
                      key={ `${product?.id}_${i}` }
                      selectedFilters={ selectedFilters }
                      layout={ layout }
                      { ...this.containerProps() }
                      onLoad={ setLoadedFlag }
                      isWidget={ isWidget }
                    />
                </AfterPriority>
            );
        });
    }

    renderItems() {
        const {
            items,
            isLoading,
            device,
            isWidget,
        } = this.props;

        if (!items.length || isLoading) {
            if (device.isMobile && isWidget) {
                return null;
            }

            return this.renderPlaceholderItems();
        }

        return (
            device?.isMobile && isWidget ? (
                <SlickSlider { ...sliderSettings }>
                    { this.renderPageItems() }
                </SlickSlider>
            ) : (
                this.renderPageItems()
            )
        );
    }
}

export default ProductListPageComponent;
